import React, {Component} from "react"
import Layout from "./../../components/layout";
import SEO from "./../../components/seo";
import WorkshopContainer from "./../../components/workshop-container";
import Workshop from "./shared/workshop";
import {InnerPage, WorkshopGrid, PageWrapper} from "./shared/default-workshop.styles";
// import styled from "styled-components";
// import COLORS from "./../../styles/colors";
// import {Link} from "gatsby";
import WorkshopData from "./workshop-data.json";

// const StyledLink = styled(Link)`
//     border: 2px solid ${COLORS.primaryColor};
//     padding: 0.5rem 1rem;
//     margin: 0.5rem 0;
//     text-decoration: none;
//     color: ${COLORS.primaryColor};
//     transition: all  0.5s;
//     display: inline-block;

//     &:hover {
//         background-color: ${COLORS.primaryColor};
//         color: #fff;
//     }
// `;

class SpecialPopulationsPage extends Component {
    render() {
        const SocialStudiesWorkshops = WorkshopData.filter(workshop => workshop.aagt);
        return (
            <Layout>
                <SEO title="Advanced Academics / Gifted and Talented | s3strategies" />
                <WorkshopContainer title="Advanced Academics / Gifted and Talented" />

                {/* <p>Each s3strategies offering can be tailored with special attention to the needs of Gifted or Special Education students.  Please determine your focus area, choose one of our workshops and allow us to customize the day based on your audience. </p>
                    <div style={{textAlign: "center"}}>
                    <StyledLink to="/workshops/">Back to Workshops</StyledLink>
                    </div> */}
                <PageWrapper>
                    <InnerPage>
                        <WorkshopGrid>
                            {SocialStudiesWorkshops.map((workshop, index) => (
                                <Workshop workshop={workshop} index={index} />
                            ))}
                        </WorkshopGrid>
                        <br />
                        <br />
                    </InnerPage>
                </PageWrapper>
            </Layout>
        )
    }
}
export default SpecialPopulationsPage;